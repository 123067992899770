import React, { useState, useEffect } from "react"
import { useQuery } from "@apollo/react-hooks"
import { produce } from "immer"
import ClickableList from "./clickable-list"
import { SCOREBOARD_FILTER, COMPANY_ID, MAP_TYPE } from "../../constants"
import { GET_LEADERBOARD } from "../../gql"
import { updateListItemOpenState } from "../../utilities/helpers"
import { sortUnitsData, sortTeams } from "../../utilities/scoreboardHelpers"
import Throbber from "../throbber"

const ScoreboardListClickable = ({
  limit,
  filter,
  meDataLoading,
  userData,
  loadMoreRank,
  skipRank,
  setSkipRank,
}) => {
  const [data, setData] = useState([])
  const [currentFetchingItem, setCurrentFetchingItem] = useState(null)

  const [reqFilters, setReqFilters] = useState({
    group: filter,
    businessUnit: "",
    country: "",
    sites: "",
  })

  const { data: requestData, loading, fetchMore } = useQuery(GET_LEADERBOARD, {
    variables: {
      pagination: {
        skip: 0,
        limit: limit,
      },
      groupBy: reqFilters.group,
      countryFilter: reqFilters.country,
      businessUnitFilter: reqFilters.businessUnit,
      sitesFilter: reqFilters.sites,
      companyId: COMPANY_ID,
    },
    fetchPolicy: "network-only",
  })

  const handleListClick = (e, item) => {
    e.preventDefault()
    e.stopPropagation()

    // If item is open, we just need to close it.
    if (item.isOpen) {
      const newData = updateListItemOpenState(produce, data, item.id, false)
      setData(newData)
    }
    // If item is not open and we don't have business units, we need to fetch them.
    else if (!item.isOpen && !item.teams) {
      // Set the country as open (loading) immediately
      const newData = updateListItemOpenState(produce, data, item.id, true)
      setData(newData)
      setCurrentFetchingItem(item)

      setReqFilters(prev => ({
        ...prev,
        group:
          item.type === "country"
            ? SCOREBOARD_FILTER.SITES
            : SCOREBOARD_FILTER.GLOBAL,
        businessUnit: item.type === "businessUnit" ? item.id : "",
        country: item.type === "country" ? item.id : "",
        sites: item.type === "sites" ? item.id : "",
      }))
    }
    // If item is not open but we already have the teams, we simply open the country.
    else if (!item.isOpen && item.teams) {
      const newData = updateListItemOpenState(produce, data, item.id, true)
      setData(newData)
    }
  }

  const getMoreRank = () => {
    fetchMore({
      variables: {
        pagination: {
          skip: skipRank + limit,
          limit: limit,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        setSkipRank(skipRank + limit)
        return {
          getReportingLeaderboardData: {
            leaderboard: [
              ...prev.getReportingLeaderboardData.leaderboard,
              ...fetchMoreResult.getReportingLeaderboardData.leaderboard,
            ],
            __typename: "Leaderboard",
          },
        }
      },
    })
  }

  useEffect(() => {
    // set the top level data
    if (
      reqFilters.group &&
      requestData &&
      requestData.getReportingLeaderboardData &&
      requestData.getReportingLeaderboardData.leaderboard &&
      !reqFilters.country &&
      !reqFilters.sites &&
      !reqFilters.businessUnit &&
      !loading &&
      userData
    ) {
      let backendData = requestData.getReportingLeaderboardData.leaderboard

      const sortedBackendData = sortUnitsData(
        backendData,
        userData,
        MAP_TYPE[reqFilters.group]
      )

      const updatedData = produce(sortedBackendData, draft => {
        draft.forEach(unit => {
          unit.isOpen = false
          unit.type = MAP_TYPE[reqFilters.group]
        })
      })
      setData(updatedData)
    }
    // to set teams data list within the top level data item
    else if (
      requestData &&
      requestData.getReportingLeaderboardData &&
      requestData.getReportingLeaderboardData.leaderboard &&
      (reqFilters.country !== "" ||
        reqFilters.sites !== "" ||
        reqFilters.businessUnit !== "") &&
      currentFetchingItem &&
      !loading &&
      userData
    ) {
      // now set the teams item within the selected item here...
      const teamsData = requestData.getReportingLeaderboardData.leaderboard
      const sortedTeamsData = sortTeams(teamsData, userData)

      const updatedData = produce(data, draft => {
        const itemIndex = draft.findIndex(
          item => item.id === currentFetchingItem.id
        )
        if (itemIndex !== -1) {
          draft[itemIndex].teams = sortedTeamsData
        }
      })
      setData(updatedData)

      // Reset currentFetchingCountry after processing
      setCurrentFetchingItem(null)
    }
  }, [requestData, reqFilters, currentFetchingItem, userData, loading])

  useEffect(() => {
    if (loadMoreRank && !loading) {
      getMoreRank()
    }
  }, [loadMoreRank])

  useEffect(() => {
    if (filter === SCOREBOARD_FILTER.COUNTRY) {
      setReqFilters(prev => ({
        ...prev,
        group: SCOREBOARD_FILTER.COUNTRY,
        businessUnit: "",
        country: "",
        sites: "",
      }))
    } else if (filter === SCOREBOARD_FILTER.BUSINESS_UNIT) {
      setReqFilters(prev => ({
        ...prev,
        group: SCOREBOARD_FILTER.BUSINESS_UNIT,
        country: "",
        businessUnit: "",
        sites: "",
      }))
    } else if (filter === SCOREBOARD_FILTER.SITES) {
      setReqFilters(prev => ({
        ...prev,
        group: SCOREBOARD_FILTER.SITES,
        country: "",
        businessUnit: "",
        sites: "",
      }))
    }
  }, [filter])

  return (
    <>
      {(loading || meDataLoading) &&
      !reqFilters.country &&
      !reqFilters.businessUnit &&
      !reqFilters.sites ? (
        <Throbber relative loadingText="Fetching data.." />
      ) : (
        <></>
      )}
      <div className="leader-board-body">
        <div className="leader_board_list">
          <ClickableList
            data={data}
            handleListClick={handleListClick}
            filters={reqFilters}
            scoreboardFilters={SCOREBOARD_FILTER}
            loading={loading}
            currentFetchingItem={currentFetchingItem}
          />
        </div>
      </div>
    </>
  )
}

export default ScoreboardListClickable
