import React from "react"
import { Trans } from "react-i18next"
import { generateRankText } from "../../utilities/formatting"
import DefaultAvatar from "../../assets/images/group.png"
import { MAP_TYPE } from "../../constants"

function extractTextFromHTML(htmlString) {
  try {
    const doc = new DOMParser().parseFromString(htmlString, "text/html")
    return doc.body.textContent === "" ? htmlString : doc.body.textContent
  } catch (error) {
    return htmlString
  }
}

const TeamList = ({ teams, type }) => {
  return (
    <div
      className={`team-list ${
        !teams || teams.length === 0 ? "no-data-bg" : ""
      }`}
    >
      {!teams || teams.length === 0 ? (
        <div className="no-data-message">
          <Trans i18nKey="scoreboard.no_teams_data" />
        </div>
      ) : (
        teams.map(team => (
          <div key={team.id} className="team-row">
            <div className="info_one">
              <div>
                <h6>{generateRankText(team.position)}</h6>
              </div>
              {type !== MAP_TYPE.COUNTRY ? (
                <img
                  className="team-image"
                  src={
                    team.teamImage && team.teamImage.path
                      ? team.teamImage.path
                      : DefaultAvatar
                  }
                  style={{ height: 30, width: 30, objectFit: "cover" }}
                  alt="Avatar"
                />
              ) : null}
              <div className="site-name">
                {extractTextFromHTML(team.teamName)}
              </div>
            </div>
            <div className="info_two score">
              {Number(team.points).toFixed(0)}
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default TeamList
