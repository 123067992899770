import React from "react"
import { generateRankText } from "../../utilities/formatting"
import { resolveTeamName } from "../../utilities/helpers"
import { Trans } from "react-i18next"
import Throbber from "../throbber"
import TeamsList from "./teams-list"
import { SCOREBOARD_FILTER } from "../../constants"
import useCurrentLang from "../../hooks/useCurrentLang"
import i18n from "i18n-iso-countries"
import "flag-icons/css/flag-icons.min.css"

const ClickableList = ({
  data,
  handleListClick,
  filters,
  scoreboardFilters,
  loading,
  currentFetchingItem,
}) => {
  const { code: langCode } = useCurrentLang()
  return data && data.length ? (
    data.map((item, index) => (
      <div
        key={`${filters.group}${item.id}`}
        className={`leader_board_member country_member ${
          item.isOpen ? "extra_padding_bottom" : ""
        }${loading ? "disable-state" : ""}`}
        onClick={loading ? null : e => handleListClick(e, item)} // disable this on loading state.
      >
        <div className="info_one">
          <div>
            <h6 className={index === 0 ? "red" : ""}>
              {generateRankText(item.position)}
            </h6>
          </div>
          {item.type === scoreboardFilters.COUNTRY.toLowerCase() &&
          item.teamName &&
          item.teamName.length === 2 ? (
            <div className="flag">
              {item.teamName === "TE" ? (
                <div className="te">TE</div>
              ) : (
                <span className={`fi fi-${item.teamName.toLowerCase()}`}></span>
              )}
            </div>
          ) : null}
          <span className="name">{resolveTeamName(item, langCode, i18n)}</span>
        </div>
        <div className="info_two score">{Number(item.points).toFixed(0)}</div>
        {/* {country.id === userData.country ? (
            <div className="my_country_rank">
              Your country ranks {generateRankText(country.position)} out of{" "}
              {data.length}.
            </div>
          ) : null} */}
        {(filters.group === SCOREBOARD_FILTER.GLOBAL ||
          filters.group === SCOREBOARD_FILTER.SITES) &&
        currentFetchingItem &&
        currentFetchingItem.id === item.id &&
        loading &&
        item.isOpen ? (
          <Throbber
            relative
            loadingText={
              filters.group === SCOREBOARD_FILTER.GLOBAL
                ? "Fetching Teams...."
                : "Fetching Sites..."
            }
          />
        ) : null}
        {item.isOpen ? <TeamsList teams={item.teams} type={item.type} /> : null}
      </div>
    ))
  ) : (
    <div className="sites_units_no_data">
      <Trans i18nKey="scoreboard.no_countries_data" />
    </div>
  )
}

export default ClickableList
