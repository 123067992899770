import i18n from "i18n-iso-countries"

const registerLanguages = () => {
  const languages = {
    cs: () => import("i18n-iso-countries/langs/cs.json"),
    de: () => import("i18n-iso-countries/langs/de.json"),
    en: () => import("i18n-iso-countries/langs/en.json"),
    es: () => import("i18n-iso-countries/langs/es.json"),
    fr: () => import("i18n-iso-countries/langs/fr.json"),
    hu: () => import("i18n-iso-countries/langs/hu.json"),
    it: () => import("i18n-iso-countries/langs/it.json"),
    ja: () => import("i18n-iso-countries/langs/ja.json"),
    ko: () => import("i18n-iso-countries/langs/ko.json"),
    nl: () => import("i18n-iso-countries/langs/nl.json"),
    pl: () => import("i18n-iso-countries/langs/pl.json"),
    pt: () => import("i18n-iso-countries/langs/pt.json"),
    th: () => import("i18n-iso-countries/langs/th.json"),
    zh: () => import("i18n-iso-countries/langs/zh.json"), // Note: Adjust if you need specific variants like zh-HK
  }

  Object.keys(languages).forEach(lang => {
    languages[lang]().then(localeData => {
      i18n.registerLocale(localeData.default)
    })
  })
}

export default registerLanguages
