import React, { useEffect, useState } from "react"
import LayoutContainer from "../components/layout"
import { useInView } from "react-intersection-observer"
import { useQuery } from "@apollo/react-hooks"
import TETorch from "./../assets/brands/te/te-torch.png"
import { Trans, withTranslation } from "react-i18next"
import ScoreboardListClickable from "../components/scoreboard/scoreboard-list-clickable"
import { SCOREBOARD_FILTER_OPTIONS, SCOREBOARD_FILTER } from "../constants"
import { ME } from "../gql"
import Filters from "../components/filters"
import registerLanguages from "../utilities/registerLanguages"

const limit = 1000

registerLanguages()

const Scoreboard = ({ location }) => {
  const [skipRank, setSkipRank] = React.useState(0)
  const [ref, loadMoreRank] = useInView()
  const [userData, setUserData] = useState(null)
  const [filter, setFilter] = useState(SCOREBOARD_FILTER.COUNTRY)

  const { data: meData, loading: meDataLoading } = useQuery(ME)

  useEffect(() => {
    setSkipRank(0)
  }, [filter])

  useEffect(() => {
    if (meData && meData.me) {
      setUserData(meData.me)
    }
  }, [meData, meDataLoading])

  return (
    <LayoutContainer seoTitle="Leaderboard">
      <div className="flex-center">
        <img src={TETorch} className="torch-image" />
        <h1 className="for_heading big-title" style={{ textAlign: "center" }}>
          <Trans i18nKey={"leaderboard.title"} />
        </h1>
      </div>
      <Filters
        filterOptions={SCOREBOARD_FILTER_OPTIONS}
        filter={filter}
        setFilter={setFilter}
        page="scoreboard"
        loading={meDataLoading}
      />
      <div className="warn-msg">
        <p className="lastUpdatedMessage">
          <Trans i18nKey={"leaderboard.warn_msg"} />
        </p>
      </div>
      <section className="leader-board">
        <div className="leader-board-body">
          <ScoreboardListClickable
            limit={limit}
            filter={filter}
            meDataLoading={meDataLoading}
            userData={userData}
            loadMoreRank={loadMoreRank}
            skipRank={skipRank}
            setSkipRank={setSkipRank}
          />
          <div ref={ref}></div>
        </div>
      </section>
    </LayoutContainer>
  )
}

export default withTranslation()(Scoreboard)
